.tag {
  display: inline-block;
  font-size: 1.1em;
  background-color: lightgrey;
  border-radius: 30px;
  text-align: center;
  padding: 1.7% 3.4%;
  margin-top: 10px;
  margin-right: 3%;
  cursor: pointer;
}

.tag svg {
  margin-bottom: -0.12rem;
  margin-right: 0.3rem;
}