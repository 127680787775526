
.mock-browser-top {
  height: 0.55em;
  display: flex;
  background-color: #e8e8e8;
  padding-left: 0.125em;
  padding-top: 0.125em;
}

.mock-browser-circle {
  background-color: #979799;
  border-radius: 50%;
  height: 0.25rem;
  width: 0.25rem;
  margin-left: 0.125rem;
}

.mock-browser-outline {
  display: grid;
  border: solid 1px #d3d3d3;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}

.project-video {
  width: 100%;
}