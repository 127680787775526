.company-logo-display-container {
  display: flex;
  margin: 5em;
  margin-left: 10em;
  margin-right: 10em;
  flex-wrap: wrap;
  justify-content: center;
}

.company-logo-display-container img {
  filter: grayscale(1) brightness(1);
  width: 6em;
  object-fit: contain;
  margin: 2em 3em;
  transition: filter 0.1s ease-in-out, transform 0.1s ease-in-out;
}

.company-logo-display-container img:hover {
  filter: initial;
  transform: scale(1.05);
}

.company-logo-display-container a {
  display: flex;
}

.tech-projects-header {
  font-size: 1.5em;
  text-align: center;
  margin: 2em 0;
}

.projects-display-container {
  display: grid;
  grid-column-gap: 8rem;
  grid-row-gap: 4rem;
  padding-right: 8rem;
  padding-left: 8rem;
  padding-top: 5rem;
  color: #fff;
  background-color: transparent;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 400px);
}

.small-text {
  font-size: 12px;
}

.spacing-element-between-logos-and-projects {
  /* Not being used at the moment, but available if necessary */
}

@media (max-width: 480px) {
  .company-logo-display-container {
    margin: 1em;
  }
  
  .company-logo-display-container img {
    margin: 2em 2em;
    /* filter: initial brightness(1); */
  }
}

@media (min-width: 1680px) {
  .company-logo-display-container {
    margin-top: 2em;
    margin-left: 17em;
    margin-right: 17em;
  }
}