.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 200px;
  padding: 0px 20px;
  background-color: transparent;
}

.contact-logos {
  text-decoration: none;
  padding: 10px;
  margin: 0px 10px;
  font-size: 30px;
  color: #111;
  height: 50px;
  float: right;
}

.icons-container {
  position: relative;
  width: 100%;
  background-color: transparent;

}

.footer-text {
  margin-top: auto;
  margin-right: 10px;
  text-align: right;
  background-color: transparent;

}

.footer-text p {
  font-size: 1em;
  line-height: 1.6em;
  margin: 0px;
  background-color: transparent;

}

.email-div {
  text-align: right;
  text-decoration: none;
  font-size: 15px;
  margin-bottom: 10px;
  margin-right: 10px;
  background-color: transparent;
}
