body { 
	margin: 0 auto; 
  margin-top: 0.5em;
	float: none; 
}

#root {
  position: relative;
}

* {
  box-sizing: border-box;
  font-family: 'Karla', sans-serif;
  font-weight: 300;
  scroll-behavior: smooth;
}

p {
  font-size: 1.1em;
  line-height: 1.8em;
  white-space: pre-line;
}

.side-button {
  background-color: transparent;
  color: black;
  border: none;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  left: 5px;
  top: 50vh;
  transform: rotate(270deg);
}

a {
  text-decoration: none;
  color: black;
}

button {
  font-size: 0.9em;
}

a:visited { 
 text-decoration: none; 
 color: black; 
}

canvas {
  cursor: ew-resize;
}

@media (max-width: 480px) {

  .side-button {
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    padding-top: 3px;
    transform: rotate(0);
    font-size: 0.8em;
  }
}

@media (min-width: 1441px) {
  
  .side-button {
    /* Increases font size, while still at side */
    font-size: 1.17em;
  }
}

@media (min-width: 1681px) {

  .side-button {
    /* Moves the button to the side */
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    margin-top: 0.2em;
    margin-right: 0.8em;
    transform: rotate(0);
  }
}

@media (min-width: 2160px) {

  .side-button {
    font-size: 1.755em;
  }
}


