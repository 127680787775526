.projects-container {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-auto-rows: auto;
  grid-row-gap: 10em;
  margin: 10vw 14vw 8vw 14vw;
}

.project-specs-container {
  color: black;
  padding-left: 3em;
}

.project-image {
  width: 100%;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.project-title {
  font-size: 2em;
  margin: 0;
  padding-bottom: 12px;
  font-weight: 400;
}

.project-title.japanese {
  font-size: 1.8em;
  margin: 0;
  padding-bottom: 12px;
  font-weight: 400;
}

.project-short-text {
  line-height: 1.4em;
  padding-top: 12px;
  padding-bottom: 12px;
}

.tech-logo-container {
  display: flex;
  padding-top: 12px;
}

.tech-logo {
  width: 45px;
  object-fit: contain;
  padding-right: 10px;
  padding-left: 10px;
  filter: grayscale(1) brightness(1);
  transition: filter 0.1s ease-in-out;
}

.tech-logo:hover {
  filter: initial;
}

@media (max-width: 480px) {
  .projects-container {
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 7vh;
    margin-top: 8em;
    margin-right: 1em;
    margin-bottom: 1em;
    margin-left: 1em;
  }

  .project-specs-container {
    margin-top: -2em;
    margin-bottom: 4em;
    padding-left: 0;
  }
}

@media (min-width: 1440px) {
  .projects-container {
    margin-top: 10em;
  }
}