b {
  font-weight: 500;
}

.small-text {
  display: block;
  color: grey;
  font-size: 0.5em;
}

.spacing-element-after-about-header {
  height: 2em;
}

.about-p, .values-p {
  margin: 0 15em;
}

.values-p:last-of-type::after {
  content: none;
}

.project h1:after {
  position: absolute;  
  content: '';
  left: 2%;
  top: 10%;
  width: 96%;
  height: 1px;
  background-color: rgba(0,0,0,.3);
}

.about-header {  
  text-align: center;
}

img {
  width: 50%;
}

.values-header {
  text-align: center;
  margin-top: 5em;
  font-weight: 400;
}

.values-header:first-of-type {
  text-align: center;
  margin-top: 2em;
  font-weight: 400;
}

.photo-of-me {
  display: flex;
  justify-content: center;
  margin: 3em;
  transform: rotate(2deg);
}

.spacing-element-after-photo {
  height: 2em;
}

@media (max-width: 480px) {
  .about-p, .values-p {
    margin: 0 1.5em;
  }

  .photo-of-me {
    margin: 3em 0;
    transform: rotate(2deg);
  }

  img {
    width: 80%;
  }

  .values-header, .about-header {
    margin-left: 1.5em;
    margin-right: 1.5em;
  }
}

@media (min-width: 1680px) {
  .about-p, .values-p {
    margin: 0 20em;
  }
}

@media (min-width: 1920px) {
  .about-p, .values-p {
    margin: 0 25em;
  }

    img {
    width: 44%;
  }
}

@media (min-width: 2160px) {
  .about-p, .values-p {
    margin: 0 35em;
  }

  img {
    width: 34%;
  }
}

@media (min-width: 2560px) {
  .about-p, .values-p {
    margin: 0 45em;
  }

  img {
    width: 34%;
  }
}