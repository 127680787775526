.photo-slideshow-container {
  position: relative;
  overflow: hidden;
  height: fit-content;
}

.slideshow-panel-left {
  position: absolute;
  height: 100%;
  width: 8%;
}

.slideshow-panel-right {
  position: absolute;
  right: 0;
  height: 100%;
  width: 8%;
}
