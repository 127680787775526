.custom-message-container {
    right: 0;
    top: 0;
    position: fixed;
    text-align: right;
}

.custom-message {
    font-size: 1.5em;
    color: white;
    margin-right: 0.2em;
    padding: 1px 6px;
}

.custom-message-text {
    background-color: black;
    padding: 0 2px;
    margin-bottom: 10px;
    padding: 2px 8px;
    line-height: 40px;
    white-space: pre;
}

.custom-message-hide {
    display: none;
}