canvas {
  height: 520px;
}

.spacing-element-for-canvas {
  height: 480px;
  z-index: -1;
}

.external-links {
  background-color: transparent;
  border: none;
  position: relative;
  z-index: 2;
  cursor: pointer;
  margin-right: 0.2em;
}

/* External links: Styling intended to match localization.css */

a:hover {
  color: grey;
}

@media (max-width: 480px) {
  .external-links > svg {
    font-size: 0.8em;
    padding-left: 3px;
    padding-right: 3px;
  }
}

@media (min-width: 1441px) {
  .external-links > svg {  
    /* Same font size as H3 */
    font-size: 1.17em; 
    margin-top: 0.2em;
    margin-right: 0.8em;
  } 

}

@media (min-width: 2160px) {
  .external-links > svg {
    font-size: 1.755em;
  }
  
}

/* External links */

.sphereHtml {
  background-color: transparent;
  cursor: crosshair;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.arrow-positioning-context {
  position: relative;
  margin-top: 2em;
  padding-bottom: 10px;
}

@media (max-width: 480px) {
  .spacing-element-for-canvas {
    height: 400px;
  }
}

@media(min-width: 1441px) {
    .spacing-element-for-canvas {
      height: 550px;
    }
}

@media (min-width: 1680px) {
    .spacing-element-for-canvas {
      height: 600px;
    }
}

@media (min-width: 1920px) {
    .spacing-element-for-canvas {
      height: 760px;
    }
}

@media (min-width: 2160px) {
      .spacing-element-for-canvas {
        height: 900px;
    }

      .spacing-element-between-arrow-and-logos {
        height: 150px;
    }
}

@media (min-width: 1920px) {
      .spacing-element-for-canvas {
        height: 1000px;
    }

    .spacing-element-between-arrow-and-logos {
      height: 200px;
    }
}