/* The main header is inline-block to maximize the space on the canvas where the cursor is ew-resize */
.header {
  display: inline-block;
  position: relative;
  text-align: center;
  z-index: 1;
  margin-left: 50%;
  transform: translate(-50%, 0);
}

.header-small {
  text-align: center;
  top: 2px;

  font-size: 20px;
  cursor: pointer;
}

.city {
  cursor: crosshair;
}

.selected {
  text-shadow: -3px 5px 4px darkgrey
}

@media (max-width: 480px) {
  
  .header {
    display: block;
    margin: 0;
    padding-top: 1.5em;
    transform: translate(0, 0);
  }

  .header h2 {
    display: none;
  }

  .japanese.header h3 {
    margin: 0 auto;
    width: 70%;
    }
}

@media (min-width: 2160px) {
  .header h1 {
    font-size: 3em;
  }

  .header h2 {
    font-size: 2.25em;
  }

  .header h3 {
    font-size: 1.755em;
  }
}