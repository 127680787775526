.nav-arrow {
  position: absolute;
  z-index: 2;
  margin: 0 auto;
  cursor: pointer;
}

.down.nav-arrow-left-tip, 
.down.nav-arrow-right-tip {
  position: absolute;
  z-index: 2;
  width: 1px;
  height: 15px;
}

.left.nav-arrow-left-tip, 
.left.nav-arrow-right-tip,
.right.nav-arrow-left-tip, 
.right.nav-arrow-right-tip {
  position: absolute;
  z-index: 2;
  width: 1px;
  height: 9px;
}

/* For downwards-pointing arrow */

.down.nav-arrow {
  position: absolute;
  height: 30px;
  width: 51px;
  left: 50%;
  transform: translate(-50%, 0);
}

.down.nav-arrow-left-tip {
  position: absolute;
  transform: rotate(-55deg);
  left: 19px;
  bottom: 7px;
}

.down.nav-arrow-right-tip {
  position: absolute;
  transform: rotate(55deg);
  right: 19px;
  bottom: 7px;
}

.left.nav-arrow,
.right.nav-arrow {
  width: 40%;
  aspect-ratio: 1 / 1.5;
  cursor: pointer;
  opacity: 0;
  transition: opacity .3s;
  z-index: 1;
}

@media (max-width: 480px) {
  .left.nav-arrow,
  .right.nav-arrow{
    opacity: 1;
  }
}

.nav-arrow-container-height-full {
  height: 100%;
}

.nav-arrow-container-height-small {
  height: 60%;
}

.left.nav-arrow:hover,
.right.nav-arrow:hover {
  opacity: 1;
} 

/* For left-pointing arrow */

.left.nav-arrow-left-tip {
  transform: translate(0, 8px) rotate(-45deg);
  left: 11px;
  bottom: 13px;
}

.left.nav-arrow-right-tip {
  transform: translate(0, 2px) rotate(45deg);
  left: 11px;
  bottom: 13px;
}

/* For right-pointing arrow */

.right.nav-arrow-left-tip {
  transform: translate(0, 2px) rotate(-45deg);
  right: 11px;
  bottom: 13px;
}

.right.nav-arrow-right-tip {
  transform: translate(0, 8px) rotate(45deg);
  right: 11px;
  bottom: 13px;
}

/* For arrow color */

.white-arrow.nav-arrow-left-tip, 
.white-arrow.nav-arrow-right-tip {
  background-color: white;
}

.black-arrow.nav-arrow-left-tip, 
.black-arrow.nav-arrow-right-tip {
  background-color: black;
}

.grey-arrow.nav-arrow-left-tip, 
.grey-arrow.nav-arrow-right-tip {
  background-color: grey;
}

.transparent-arrow.nav-arrow-left-tip, 
.transparent-arrow.nav-arrow-right-tip {
  background-color: transparent;
}

/* For slideshow arrows */

/* IMPORTANT: You must put this class inside an element with a positioning context */

.slideshow-arrow-left {
  /* Placeholder for css that affects the left hand side panel */
}

.slideshow-arrow-right {
  right: 0;
}

/* For arrow circle */

.arrow-circle {
  position: absolute;
  top: 50%;
  transform: translate(0,-50%);
  background-color: #47464d;
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

.nav-arrow-container-height-small > .arrow-circle {
  /* This is so that the arrow circles are centered vertically when the panel is reduced to 60% for videos */
  top: 83%
}

.arrow-circle-right {
  right: 10px;
}

.arrow-circle-left {
  left: 10px;
}
