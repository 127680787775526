.localization-container {
  width: 50%;
  display: inline-block;
}

.localization-button {
  background-color: transparent;
  color: black;
  border: none;
  position: relative;
  z-index: 2;
  cursor: pointer;
  margin-left: 0.2em;
}

button:hover {
  color: grey;
}

@media (max-width: 480px) {
  .localization-button {
    font-size: 0.8em;
    padding-left: 3px;
    padding-right: 3px;
  }
}

@media (min-width: 1441px) {
  .localization-button {  
    /* Same font size as H3 */
    font-size: 1.17em; 
    margin-top: 0.2em;
    margin-left: 0.8em;
  } 
}

@media (min-width: 2160px) {

  .localization-button {
    font-size: 1.755em;
  }
}